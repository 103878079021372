.custominput {
    border-radius: 5px;
    font-size: 14px;
}

.cardmargin {
    margin-bottom: px; 
  }

  
.custombutton {
    padding: 2px 10px;
    border: none;
    background-color: #87CEFA;
    border-radius: 5px;
    color: #fff;
    cursor: pointer; 
}

.blacktext {
    color: black;
  }

  .overflow {
    overflow: scroll;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
    bottom: 5%;
    right: 5%;
  }

  .padding {
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #F6F6F6;
  }
  
  .card {
    height: 220px;
    overflow: scroll;
    color: black;

    
}

.font700{
  font-weight:700;
}