.popup_container_frist_stage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  /* background-color: rgb(202, 197, 197); */
}
.popup_first_stage {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 15rem;
}

.checkbox_first_stage {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.7rem;
  /* padding:rem; */
}

/* PasswordChangeForm.module.css */

.container_change_pass {
max-width: 28rem;
margin: 0 auto;
padding: 20px;
margin-top: 1.7rem;
border: 1px solid #ccc;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}


/* i {
margin-left: 0.4rem;
margin-top: 0.4rem;
} */

.inputContainer {
display: flex;
flex-direction: column;
margin-bottom: 15px;
}

.change_password_heading {
/* margin-left: 20rem; */
text-align: center;
margin-top: 2rem;
font-weight: 600;
color: gray;
}

.change_password_label {
font-weight: bold;
}

.change_password_input {
padding: 10px;
border: 1px solid #ccc;
border-radius: 5px;
}

.change_password_btn {
padding: 10px 20px;
background-color: #007bff;
color: #fff;
border: none;
border-radius: 5px;
cursor: pointer;
}

.error {
color: red;
margin-bottom: 10px;
}

.success {
color: green;
margin-bottom: 10px;
}