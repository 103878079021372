.leftWrapper{
    max-height: 900px;
    padding-right: 1px;
}

.leftSubWrapper{
    max-height: 600px;
    overflow:scroll;
}

.font700{
    font-weight:700;
}

.font600{
    font-weight:600;
}

.m1{
    margin-left: 1.5rem;
    color:black;
}

.inputStyle{
    border-radius : 5px;
    font-size :  14px;
}

.colorblack{
    color:black;
}

.rightWrapper{
    max-height : 900px;
    padding-left : 1px;
    overflow:hidden;
}

.position_relative{
    position:relative;
}

.buttons{
    padding : 2px 10px;
    border: none;
    background-color: #87CEFA;
    border-radius: 5px;
    color: #fff;
}

.m2{
    padding-left: 10px;
    padding-right: 10px;
}

.m3{
    height: 300px;
    overflow: scroll;
    color:black;
}


