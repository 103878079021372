.tool-icon {
    transition: transform 0.2s ease-in-out;
  }
  
.tool-icon:hover {
    transform: scale(1.1);
 }
  
.minHeight {
     min-height: 700px;
 }