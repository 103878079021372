.email-ids-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .email-id-box {
    width: auto;
    background-color: #cfcbcb;
    border: 1px solid #ccc;
    color:#333;
    border-radius: 10px;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  .CheckBoxScale {
    scale: 2;
  }
  .DataGridHeight {
    height: 400px;
  }
  
  .remove-email-btn {
    background-color: transparent;
    border: none;
    color: red;
    margin-left: 5px;
    cursor: pointer;
  }


  