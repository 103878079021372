.chat1{
    width: 95vw;
    height: 100vh;
    position: fixed;
    right: -95vw;
    top: 0;
    z-index: 999;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.15);
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}

.chat1 .chat1close{
    width:0vw;
    right:95vw;
}

@media(max-width:1280px){
    .chat1{
       width: 60rem;
       right: -60rem;
    }

    .chat1 .chat1close{
       right:60rem;
    }
}


@media(max-width:1024px){
    .chat1{
       width: 45rem;
       right: -45rem;
    }

    .chat1 .chat1close{
       right:45rem;
    }
}

@media(max-width:900px){
     .chat1{
        width: 40rem;
        right: -40rem;
     }

     .chat1 .chat1close{
        right:40rem;
     }
}

@media(max-width:600px){
    .chat1{
       width: 30rem;
       right: -30rem;
    }

    .chat1 .chat1close{
       right:30rem;
    }
}

@media(max-width:435px){
    .chat1{
       width: 20rem;
       right: -20rem;
    }

    .chat1 .chat1close{
       right:20rem;
    }
}

@media(max-width:335px){
    .chat1{
       width: 18rem;
       right: -18rem;
    }

    .chat1 .chat1close{
       right:18rem;
    }
}