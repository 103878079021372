.report-icon {
  transition: transform 0.2s ease-in-out;
}

.report-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.no-scrollbar .MuiDataGrid-root {
  overflow: hidden !important;
}

.no-scrollbar .MuiDataGrid-window {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.DataGridHeightClass {
  height: 700px;
}

.ColorCustom {
  color: #F6FAFD;
}

.ColorCustomSecond {
  color: #3C76D2;
}

.fontWFirst {
  font-weight: 600;
}
.fontWFirstSecond {
  font-weight: 400;
}
  

/* .scrollbarnone::-webkit-scrollbar {
  display: none;
} */

/* styles.css */
/* .custom-scrollbar .MuiDataGrid-virtualScroller {
  scrollbar-width: thin;
  scrollbar-color: #1a1919 #222;
} */
