.inactive{
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid lightgray;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
}

.active{
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid darkgray;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    transition: 0.3s linear;
}

.leftWrapper{
    max-height: 900px;
    padding-right: 1px;
}

.rightWrapper{
    max-height: 1000px;
    padding-right: 1px;
}

.leftSubWrapper{
    max-height: 600px;
    overflow:scroll;
}

.inputStyle{
    border-radius : 5px;
    font-size :  14px;
}

.font700{
    font-weight:700;
}

.colorblack{
    color:black;
}

.font1{
    font-size: 1rem;
}

.fonthalfrem{
    font-size:1.5rem;
}

.position_relative{
    position : relative;
}

.position_absolute{
    position : absolute;
    right:10;
    top: 2%;
}

.buttons{
    padding: 2px 10px;
    border:none;
    background-color: #87CEFA;
    border-radius: 5px;
    color:#fff;
}

.misleneousclass1{
    padding-left: 10px;
    padding-right: 10px;
}

.misleneousclass2{
    height: 300px;
    overflow: scroll;
    color:black;
}