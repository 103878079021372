.leftWrapper{
    max-height : 600px;
    padding-right : 1px;
}

.leftSubWrapper{
    max-height : 600px;
    overflow : scroll;
    margin-top: 50px;
}

.font700{
    font-weight: 700;
}

.m1{
    color:black;
    margin-left:1.5rem;
}

.colorblack{
    color : black;
}

